import React, { useReducer } from 'react';
import _ from 'lodash';

const Context = React.createContext();

const workoutsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_WORKOUTS':
      return [...action.payload];
    case 'CREATE_WORKOUT':
      return [action.payload, ...state];
    case 'DELETE_WORKOUT':
      return _.filter(state, w => w._id !== action.payload._id);

    default:
      return state;
  }
};

export const WorkoutsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(workoutsReducer, []);

  return (
    <Context.Provider value={{ workouts: state, workoutsDispatch: dispatch }}>
      {children}
    </Context.Provider>
  );
};

export default Context;

import { Global } from '@mantine/core';

const MyGlobalStyles = () => {
  return (
    <Global
      styles={theme => ({
        a: {
          textDecoration: 'none',
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        },
      })}
    />
  );
};

export default MyGlobalStyles;

import {
  ActionIcon,
  Badge,
  Card,
  Group,
  Text,
  Title,
  useMantineColorScheme,
} from '@mantine/core';
import { useContext } from 'react';
import { MdFitnessCenter } from 'react-icons/md';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import workoutsContext from '../contexts/workoutsContext';
import backendAPI from '../apis/backendAPI';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

const WorkoutDetail = ({ workout: { _id, title, reps, load, createdAt } }) => {
  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === 'dark';
  const { workoutsDispatch } = useContext(workoutsContext);

  const onWorkoutDelete = async () => {
    console.log(_id);
    const { data } = await backendAPI.delete(`/workouts/${_id}`);
    workoutsDispatch({ type: 'DELETE_WORKOUT', payload: data });
  };

  return (
    <Card shadow={isDark ? 'md' : 'sm'}>
      <Group position="apart">
        <Title order={5}>{title}</Title>
        <Badge component={MdFitnessCenter} />
      </Group>
      <Text size="sm">Load (kg): {load}</Text>
      <Text size="sm">Reps: {reps}</Text>
      <Text size="xs" color="dimmed">
        Added {formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
      </Text>
      <Group mt="xs">
        <ActionIcon color="red" variant="light" onClick={onWorkoutDelete}>
          <RiDeleteBin5Fill />
        </ActionIcon>
      </Group>
    </Card>
  );
};

export default WorkoutDetail;

const getTheme = (colorScheme = 'light') => ({
  // colors
  colorScheme,
  colors: {
    brand: [
      '#85EAEB',
      '#6AE6E7',
      '#50E1E3',
      '#36DCDF',
      '#22D1D4',
      '#1EB7B9',
      '#199D9F',
      '#158384',
      '#11696A',
      '#0D4E4F',
    ],
    dark: [
      // black feather :)
      '#B7BBBC',
      '#878D90',
      '#7A8284',
      '#4A5458',
      '#1A262B',
      '#0D1A1E',
      '#0B1418',
      '#080F12',
      '#070E10',
      '#04080A',
    ],
  },
  primaryColor: 'brand',
  defaultGradient: {
    from: 'brand',
    to: 'brand.9',
    deg: 45,
  },

  // font
  fontFamily: 'Nunito, sans-serif',
  headings: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
  },

  // shadows
  shadows: {
    xs: '1px 1px 1px rgba(0, 0, 0, 0.3)',
    sm: '1px 1px 4px rgba(0, 0, 0, 0.4)',
    md: '3px 3px 4px rgba(0, 0, 0, 0.4)',
    lg: '3px 3px 4px 5px rgba(0, 0, 0, 0.4)',
    xl: '3px 3px 4px 15px rgba(0, 0, 0, 0.4)',
  },

  // other
  defaultRadius: 'lg',
  activeStyles: {
    transform: 'scale(0.95)',
  },
  loader: 'bars',
  cursorType: 'pointer',
  dateFormat: 'MMMM DD, YYYY',

  // components
  components: {
    Title: {
      styles: {
        root: {
          textTransform: 'capitalize',
          '&:is(h1)': {
            fontWeight: 900,
          },
        },
      },
    },
    Button: {
      defaultProps: {
        variant: 'gradient',
      },
      styles: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
  },
});

export default getTheme;

import { AppShell, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { BrowserRouter, Route } from 'react-router-dom';
// theme
import getTheme from './theme';
import MyGlobalStyles from './theme/MyGlobalStyles';
// pages, components,
import MyHeader from './components/MyHeader';
import Home from './pages/Home';
import { WorkoutsProvider } from './contexts/workoutsContext';

const App = () => {
  const [colorScheme, setColorScheme] = useLocalStorage({
    key: 'app-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });
  const toggleColorScheme = mode =>
    setColorScheme(mode || colorScheme === 'dark' ? 'light' : 'dark');

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={getTheme(colorScheme)}>
        <MyGlobalStyles />
        <WorkoutsProvider>
          <BrowserRouter>
            {/* App shell */}
            <AppShell
              header={<MyHeader />}
              styles={theme => ({
                main: {
                  backgroundColor:
                    theme.colorScheme === 'dark'
                      ? theme.colors.dark[9]
                      : theme.colors.gray[0],
                },
              })}
            >
              {/* pages */}
              <Route path="/" exact component={Home} />
            </AppShell>
          </BrowserRouter>
        </WorkoutsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;

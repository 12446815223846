import { useContext, useEffect, useState } from 'react';
import {
  ActionIcon,
  Grid,
  MediaQuery,
  Modal,
  ScrollArea,
  Stack,
  Tooltip,
} from '@mantine/core';
import { IoIosCreate } from 'react-icons/io';
import backendAPI from '../apis/backendAPI';
import WorkoutDetail from '../components/WorkoutDetail';
import WorkoutForm from '../components/WorkoutForm';
import workoutsContext from '../contexts/workoutsContext';

const Home = () => {
  const [opened, setOpened] = useState(false);
  const { workouts, workoutsDispatch } = useContext(workoutsContext);

  useEffect(() => {
    (async () => {
      const { data } = await backendAPI.get('/workouts');
      workoutsDispatch({ type: 'SET_WORKOUTS', payload: data });
    })();
  }, [workoutsDispatch]);

  const renderWorkouts =
    workouts.length > 0 &&
    workouts.map(workout => <WorkoutDetail key={workout._id} workout={workout} />);

  return (
    <>
      <Grid m={0}>
        {/* workouts */}
        <Grid.Col sm={12} md={8}>
          <ScrollArea
            scrollHideDelay={500}
            scrollbarSize={8}
            sx={{ height: 'calc(100vh - 108px)', position: 'relative' }}
          >
            <Stack justify="flex-start" spacing="xs" p="xs">
              {renderWorkouts}
            </Stack>

            <MediaQuery largerThan="md" styles={{ display: 'none' }}>
              <Tooltip label="New Workout" withArrow>
                <ActionIcon
                  variant="light"
                  size="xl"
                  radius="xl"
                  color="brand"
                  sx={theme => ({
                    position: 'absolute',
                    bottom: 10,
                    right: 26,
                    boxShadow: theme.shadows.sm,
                  })}
                  onClick={() => setOpened(true)}
                >
                  <IoIosCreate size={28} />
                </ActionIcon>
              </Tooltip>
            </MediaQuery>
          </ScrollArea>
        </Grid.Col>

        {/* workout form */}
        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
          <Grid.Col md={4}>
            {/* form */}
            <WorkoutForm />
          </Grid.Col>
        </MediaQuery>
      </Grid>

      {/* workout form modal */}
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        centered
        withCloseButton={false}
      >
        <WorkoutForm />
      </Modal>
    </>
  );
};

export default Home;

import { Box, Header, MediaQuery, Title } from '@mantine/core';
import { Link } from 'react-router-dom';

const MyHeader = () => {
  return (
    <Header height={60} px="md">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Link to="/">
          <MediaQuery smallerThan="sm" styles={{ fontSize: 18 }}>
            <Title order={2}>Fitness Buddy</Title>
          </MediaQuery>
        </Link>
      </Box>
    </Header>
  );
};

export default MyHeader;

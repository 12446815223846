import { Button, NumberInput, Stack, TextInput, Title } from '@mantine/core';
import { useContext } from 'react';
import { Form, Field } from 'react-final-form';
import backendAPI from '../apis/backendAPI';
import workoutsContext from '../contexts/workoutsContext';

const WorkoutForm = () => {
  const { workoutsDispatch } = useContext(workoutsContext);

  const onFormSubmit = async formValues => {
    const { data } = await backendAPI.post('/workouts', formValues);
    workoutsDispatch({ type: 'CREATE_WORKOUT', payload: data });
  };

  const validateForm = formValues => {
    const error = {};
    if (!formValues.title) error.title = 'Title is required';
    if (!formValues.load) error.load = 'Load is required';
    if (!formValues.reps) error.reps = 'Reps is required';
    return error;
  };

  const MyTextInput = ({ input, meta, custom }) => {
    return (
      <TextInput
        {...input}
        {...custom}
        withAsterisk
        error={meta.touched && meta.error ? meta.error : ''}
      />
    );
  };
  const MyNumberInput = ({ input, meta, custom }) => {
    return (
      <NumberInput
        {...input}
        {...custom}
        withAsterisk
        error={meta.touched && meta.error ? meta.error : ''}
      />
    );
  };

  const renderForm = ({ handleSubmit, form }) => (
    <form
      onSubmit={e => {
        const promise = handleSubmit(e);
        promise &&
          promise.then(() => {
            form.reset();
            form.resetFieldState('title');
            form.resetFieldState('load');
            form.resetFieldState('reps');
          });
        return promise;
      }}
      autoComplete="off"
    >
      <Stack justify="flex-start" spacing="xs" p="xs">
        <Title order={3}>workout form</Title>
        <Field
          name="title"
          component={MyTextInput}
          custom={{ placeholder: 'Your title', label: 'Workout Title' }}
        />
        <Field
          name="load"
          component={MyNumberInput}
          custom={{ placeholder: 'Number of kgs', label: 'Load (kg)' }}
        />
        <Field
          name="reps"
          component={MyNumberInput}
          custom={{ placeholder: 'Number of Reps', label: 'Total Reps' }}
        />
        <Button type="submit">Submit</Button>
      </Stack>
    </form>
  );

  return (
    <Form
      initialValues={{ title: '', load: 0, reps: 0 }}
      render={renderForm}
      validate={validateForm}
      onSubmit={onFormSubmit}
    />
  );
};

export default WorkoutForm;
